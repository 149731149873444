import Model from "../Model";

class HpList extends Model {
    buildUrl ({ param }) {
        return ['hubspot-contact-lists', param];
    }

    async syncUp(form) {
        return await this.request({
            method: 'POST',
            url: 'hubspot-contact-lists/syncUp',
            data: form
        });
    }
}

export default new HpList;